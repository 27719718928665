import { Button, Select } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "../../../_helpers/debounce";

function Search() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});
  function handleFilterInput(field) {
    const debouncedFilter = debounce((value) => {
      setFilter((prev) => {
        let temp = { ...prev };
        temp[field] = value;
        return temp;
      });
    }, 300);

    return (e) => {
      debouncedFilter(e?.target?.value);
    };
  }

  function handleSearch() {
    const queries = Object.entries(filter)
      ?.map((f) => f.join("="))
      .join("&");
    navigate(`/listing?${queries}`);
  }

  return (
    <>
      <div className=" md:block hidden absolute bottom-0">
        <div
          className={[
            "relative py-6 px-12 rounded-t-full grid grid-cols-2 md:grid-cols-5 gap-2 overflow-clip",
          ].join(" ")}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-white/50 to-black blur-sm" />
          {/* <TextInput
						className=" col-span-2"
						placeholder="Search by title"
						onChange={handleFilterInput('name')}
					/> */}
          <Select
            className=" col-span-2"
            placeholder="Select budget"
            onChange={handleFilterInput("budget")}
            // sizing="lg"
          >
            <option disabled selected>
              Select Budget
            </option>
            <option value="0-10000000">{"<"}1 Crore</option>
            <option value="10000000-30000000">1 Crore - 3 Crore</option>
            <option value="30000000-50000000">3 Crore - 5 Crore</option>
            <option value="50000000-100000000">5 Crore - 10 Crore</option>
            <option value="100000000-">{">"} 10 Crore</option>
          </Select>
          <Select
            onChange={handleFilterInput("category")}
            // sizing="lg"
          >
            <option value="" selected disabled>
              Type
            </option>
            <option value="house">House</option>
            <option value="apartment">Apartment</option>
            <option value="land">Land</option>
          </Select>
          <Select
            onChange={handleFilterInput("district")}
            // sizing="lg"
          >
            <option value="" selected disabled>
              Location
            </option>
            <option value="kathmandu">Kathmandu</option>
            <option value="lalitpur">Lalitpur</option>
            <option value="bhaktapur">Bhaktapur</option>
            <option value="chitwan">Chitwan</option>
          </Select>
          <div className=" flex space-x-2">
            <Button
              className="grow"
              // sizing="lg"
              color="primary"
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
