//
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Breadcrumb,
  Button,
  Checkbox,
  FileInput,
  Label,
  Select,
  Table,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaFileImage } from "react-icons/fa";
import { HiPlus, HiSaveAs } from "react-icons/hi";
import { MdOutlineDelete } from "react-icons/md";

import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../_contexts/AlertContext";
import { propertySchema } from "../../../_helpers/formSchema";
import normalizeCamelCase from "../../../_helpers/normalizeCamelCase";
import {
  getProperty,
  getTeams,
  multiUpload,
  patchProperty,
  postProperty,
} from "../../../apis";
import AddressSelect from "../../../components/AddressSelect";
import ControlledEditor from "../../../components/ControlledEditor";
import Loader from "../../../components/Loader";
function PropertyCreate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate, error } = useMutation({
    mutationKey: ["properties", ...(id ? [id] : [])],
    mutationFn: id ? patchProperty : postProperty,
    onSuccess: () => {
      navigate("/admin/property/list/1");
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["property", id],
    queryFn: () => getProperty(id),
    enabled: !!id,
  });

  const { data: enquiry } = useQuery({
    queryKey: ["enquiry"],
    queryFn: () => getTeams(),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      description: data?.data?.description || "",
    },
    resolver: yupResolver(propertySchema()),
  });

  const { setAlerts } = useAlert();

  useEffect(() => {
    if (error) {
      setAlerts((prev) => [
        ...prev,
        {
          isOpen: true,
          message: error?.message,
          type: "failure",
        },
      ]);
    }
  }, [error, setAlerts]);

  useEffect(() => {
    if (data?.data) {
      setValue("description", data?.data?.description);
      setValue("location", data?.data?.location);
      setValue("premium", data?.data?.premium);
    }
  }, [data, setValue]);

  useEffect(() => {
    function findMessageKey(obj, key) {
      if (obj.hasOwnProperty(key)) {
        return obj[key];
      }

      for (let prop in obj) {
        if (typeof obj[prop] === "object") {
          let result = findMessageKey(obj[prop], key);
          if (result) {
            return result;
          }
        }
      }

      return null;
    }
    if (Object.entries(errors).length > 0) {
      // eslint-disable-next-line
      Object.entries(errors).forEach(([key, value]) => {
        let v = findMessageKey(value, "message");
        setAlerts((prev) => [
          ...prev,
          {
            isOpen: true,
            message: v || "something went wrong",
            type: "failure",
          },
        ]);
      });
      return;
    }
  }, [errors, setAlerts]);

  function onSubmit(data) {
    const body = { ...data };
    body.other = data.other?.map((o) => {
      delete o.id;
      return o;
    });
    body.otherDetails = Object.entries(body.otherDetails).map(
      ([key, value]) => {
        return { name: key, value };
      }
    );
    id ? mutate({ id, data: body }) : mutate(body);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Property</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? "Update" : "Create"}</Breadcrumb.Item>
      </Breadcrumb>
      {isLoading ? (
        <Loader />
      ) : (
        <form className="py-8 flex flex-col gap-y-8">
          <BasicInfo
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
            defaultData={data?.data}
            enquiry={enquiry?.data}
          />
          <ControlledEditor
            id="description"
            setValue={setValue}
            defaultValue={data?.data?.description || ""}
            error={errors?.description?.message}
          />
          <Location
            register={register}
            setValue={setValue}
            errors={errors}
            defaultValue={data?.data?.location}
          />
          <Area register={register} defaultData={data?.data} />
          <Details
            register={register}
            defaultData={data?.data}
            errors={errors}
          />
          <Social register={register} defaultData={data?.data} />

          <LandMark register={register} defaultData={data?.data} />
          <MainFeatures
            register={register}
            defaultData={data?.data}
            watch={watch}
          />
          <OtherInfo
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            defaultData={data?.data}
          />
          <div>
            <Label className="flex gap-x-2">
              Premium
              <ToggleSwitch
                checked={watch("premium")}
                onChange={(v) => {
                  setValue("premium", v);
                }}
              />
            </Label>
          </div>
          <div className="flex justify-end pt-8">
            <Button
              className="w-1/2"
              color="success"
              onClick={handleSubmit(onSubmit)}
            >
              Submit <HiSaveAs className="h-5 w-5 ml-4" />
            </Button>
          </div>
        </form>
      )}
    </>
  );
}

function MainFeatures({ register, defaultData, watch }) {
  const features = [
    "earthquake resistant",
    "marbal",
    "parquet",
    "cc camera",
    "drainage",
    "solar",
    "garden",
    "parking",
    "drinking water",
    "balcony",
    "reserve tank",
    "lawn",
    "terrace",
    "well",
    "boring water",
    "gazebo",
    "attic",
    "fire place",
    "heating system",
    "video door",
    "bathtub",
    "basement",
    "servant quarter",
    "Fridge",
    "Sofa",
    "Aircon",
    "Water purifier",
    "Washing machine",
    "Tv",
    "Microwave",
    "Power Backup",
    "Iron",
    "Modular kitchen",
    "Internet",
    "Closet",
    "Bed",
    "Dinning table",
    "Dressing table",
    "Curtain",
  ];

  return (
    <>
      <fieldset className="grid md:grid-cols-6 grid-cols-2 gap-4">
        <legend className="text-2xl font-medium text-lime-700 col-span-2 mb-2">
          Amenities
        </legend>

        {features?.map((option, index) => (
          <div key={index} className="flex gap-x-1">
            <Checkbox
              name={option}
              id={option}
              defaultChecked={
                defaultData?.otherDetails?.find((od) => od.name === option)
                  ?.value === "true"
              }
              checked={watch(`otherDetails.${option}`)}
              {...register(`otherDetails.${option}`)}
            />
            <Label key={index} className="flex gap-1" htmlFor={option}>
              {option}
            </Label>
          </div>
        ))}
      </fieldset>
    </>
  );
}
function Location({ register, setValue, defaultValue, errors }) {
  return (
    <>
      <fieldset className="grid md:grid-cols-3 grid-cols-1 gap-4">
        <legend className="text-2xl font-medium text-lime-700 col-span-2">
          Location
        </legend>
        <AddressSelect
          setValue={setValue}
          register={register}
          defaultValues={defaultValue}
          ids={[
            "location.province",
            "location.district",
            "location.municipal",
            "location.ward",
          ]}
          error={errors?.location}
        />
        <div>
          <Label>Area</Label>
          <TextInput
            type="text"
            placeholder="Enter area"
            {...register("location.area")}
            defaultValue={defaultValue?.area}
            helperText={errors?.location?.area?.message}
            color={errors?.location?.area?.message && "failure"}
          />
        </div>
      </fieldset>
    </>
  );
}

function Area({ register, defaultData = null }) {
  const [areaType, setAreaType] = useState("Hilly");
  const areaUnit = [
    {
      id: 1,
      name: "Hilly",
      units: ["daam", "paisa", "aana", "ropani"],
    },
    {
      id: 2,
      name: "Terai",
      units: ["dhur", "kattha", "bigha"],
    },
    {
      id: 3,
      name: "Metric",
      units: ["squareMetric"],
    },
  ];
  return (
    <>
      <fieldset className="grid">
        <legend className="text-2xl font-medium text-lime-700 col-span-2">
          Area
        </legend>

        <div>
          <Label>Type</Label>
          <Select
            {...register("area.type")}
            value={areaType}
            onChange={(e) => setAreaType(e.target.value)}
            defaultValue={defaultData?.area?.type}
          >
            <option value="Hilly">Hilly</option>
            <option value="Terai">Terai</option>
            <option value="Metric">Metric</option>
          </Select>
          <div className="flex pt-4 gap-2">
            {areaUnit?.map(
              (item) =>
                item.name === areaType &&
                item.units.map((unit) => (
                  <div className="grow relative">
                    <TextInput
                      type="number"
                      placeholder={`${unit}`}
                      {...register(`area.${unit}`)}
                      defaultValue={defaultData?.area?.[unit] || 0}
                    />
                    <Label className="absolute top-1/2 -translate-y-1/2 right-8 text-slate-400">
                      {unit}
                    </Label>
                  </div>
                ))
            )}
          </div>
        </div>
      </fieldset>
    </>
  );
}

function Details({ register, defaultData = null }) {
  const [roadAccess, setRoadAccess] = useState(
    defaultData?.details?.roadAccess || false
  );

  return (
    <>
      <fieldset className="grid md:grid-cols-3 grid-cols-1 gap-4">
        <legend className="text-2xl font-medium text-lime-700 col-span-2">
          Details
        </legend>
        <div>
          <Label>BedRoom</Label>
          <TextInput
            type="number"
            placeholder="Enter bedroom"
            defaultValue={defaultData?.details?.bedroom || 0}
            {...register("details.bedroom")}
          />
        </div>
        <div>
          <Label>Kitchen</Label>
          <TextInput
            type="number"
            placeholder="Enter kitchen"
            {...register("details.kitchen")}
            defaultValue={defaultData?.details?.kitchen || 0}
          />
        </div>
        <div>
          <Label>Bathroom</Label>
          <TextInput
            type="number"
            placeholder="Enter bathroom"
            {...register("details.bathroom")}
            defaultValue={defaultData?.details?.bathroom || 0}
          />
        </div>
        <div>
          <Label>Floors</Label>
          <TextInput
            type="number"
            placeholder="Enter floors"
            {...register("details.floors")}
            defaultValue={defaultData?.details?.floors || 0}
          />
        </div>
        <div>
          <Label>Parking</Label>
          <div className="flex gap-x-4">
            <TextInput
              type="number"
              placeholder="Enter bikes"
              {...register("details.parking.bikes")}
              className="grow"
              defaultValue={defaultData?.details?.parking?.bikes || 0}
            />
            <TextInput
              type="number"
              placeholder="Enter cars"
              {...register("details.parking.cars")}
              className="grow"
              defaultValue={defaultData?.details?.parking?.cars || 0}
            />
          </div>
        </div>
        <div>
          <Label>Road Type</Label>

          <Select
            {...register("details.roadType")}
            defaultValue={defaultData?.details?.roadType}
          >
            <option value="blackTopped">Black Topped</option>
            <option value="dhalan">Dhalan</option>
            <option value="paved">Paved</option>
            <option value="gravelled">Gravelled</option>
            <option value="soilStabilized">Soil Stabilized</option>
            <option value="alley">Alley</option>
          </Select>
        </div>
        <div>
          <Label>
            Has Road Access?{" "}
            <Checkbox
              checked={roadAccess}
              className="ml-6"
              {...register("details.roadAccess.access")}
              onChange={() => setRoadAccess(!roadAccess)}
            />
          </Label>
          {roadAccess && (
            <div className="flex gap-x-4">
              <TextInput
                type="number"
                placeholder="Enter width"
                {...register("details.roadAccess.width")}
                className="grow"
                defaultValue={defaultData?.details?.roadAccess?.width || 0}
              />

              <Select
                {...register("details.roadAccess.unit")}
                defaultValue={defaultData?.details?.roadAccess?.unit}
              >
                <option value="ft">ft</option>
                <option value="m">m</option>
              </Select>
            </div>
          )}
        </div>
      </fieldset>
    </>
  );
}
function Social({ register, defaultData = null }) {
  return (
    <>
      <fieldset className="grid grid-cols-1 gap-4">
        <legend className="text-2xl font-medium text-lime-700 col-span-2">
          Social
        </legend>
        <div>
          <Label>Youtube</Label>
          <TextInput
            type="text"
            placeholder="Enter youtube"
            {...register("social.youtube")}
            defaultValue={defaultData?.social?.youtube}
          />
        </div>
        <div>
          <Label>Tiktok</Label>
          <TextInput
            type="text"
            placeholder="Enter youtube"
            {...register("social.tiktok")}
            defaultValue={defaultData?.social?.tiktok}
          />
        </div>
        <div>
          <Label>Instagram</Label>
          <TextInput
            type="text"
            placeholder="Enter youtube"
            {...register("social.instagram")}
            defaultValue={defaultData?.social?.instagram}
          />
        </div>
        <div>
          <Label>facebook</Label>
          <TextInput
            type="text"
            placeholder="Enter youtube"
            {...register("social.facebook")}
            defaultValue={defaultData?.social?.facebook}
          />
        </div>
      </fieldset>
    </>
  );
}
function LandMark({ register, errors, defaultData = null }) {
  return (
    <>
      <fieldset className="grid md:grid-cols-3 grid-cols-1 gap-4">
        <legend className="text-2xl font-medium text-lime-700 col-span-2">
          LandMark
        </legend>
        <div>
          <Label>Type</Label>

          <Select
            {...register("landmark.type")}
            defaultValue={defaultData?.landmark?.type}
          >
            <option value="hospital">Hospital</option>
            <option value="bank">Bank</option>
            <option value="college">College</option>
            <option value="temple">Temple</option>
            <option value="govtOffice">Government Office</option>
            <option value="other">Other</option>
          </Select>
        </div>
        <div>
          <Label>Name</Label>
          <TextInput
            type="text"
            placeholder="Enter name"
            {...register("landmark.name")}
            defaultValue={defaultData?.landmark?.name}
          />
        </div>
        <div>
          <Label>Coordinates</Label>
          <TextInput
            type="text"
            placeholder="Enter coordinates"
            {...register("landmark.coordinates")}
            defaultValue={defaultData?.landmark?.coordinates}
            helperText={errors?.landmark?.coordinates?.message}
            color={errors?.landmark?.coordinates?.message && "failure"}
          />
        </div>
      </fieldset>
    </>
  );
}
function BasicInfo({ register, errors, defaultData = null, enquiry }) {
  return (
    <>
      <fieldset className="grid md:grid-cols-3 grid-cols-1 gap-4">
        <legend className="text-2xl font-medium text-lime-700 col-span-2">
          Basic Information
        </legend>
        <div>
          <Label>Category</Label>
          <Select
            {...register("category")}
            color={errors?.category?.message && "failure"}
            helperText={errors?.category?.message}
            defaultValue={defaultData?.category || "house"}
          >
            <option value="house">House</option>
            <option value="apartment">Apartment</option>
            <option value="land">Land</option>
          </Select>
        </div>
        <div>
          <Label>Sub Category</Label>
          <Select
            {...register("subCategory")}
            color={errors?.subCategory?.message && "failure"}
            helperText={errors?.subCategory?.message}
            defaultValue={defaultData?.subCategory || "Bungalow"}
          >
            <option value="Bungalow">Bungalow</option>
            <option value="Commercial house">Commercial House</option>
            <option value="Duplex">Duplex</option>
            <option value="2 BHK">2 BHK</option>
            <option value="Mansion">Mansion</option>
            <option value="Triplex">Triplex</option>
            <option value="Villa">Villa</option>
            <option value="3 BHK">3 BHK</option>
            <option value="4 BHK">4 BHK</option>
            <option value="Others">Others</option>
            <option value="Commercial Land">Commercial Land</option>
            <option value="Residential Land">Residental Land</option>
            <option value="Apartment">Apartment</option>
            <option value="Flat">Flat</option>
            <option value="Office Space">Office Space</option>
            <option value="Shop Space">shopSpace</option>
          </Select>
        </div>
        <div>
          <Label>Type</Label>
          <Select
            {...register("type")}
            color={errors?.type?.message && "failure"}
            helperText={errors?.type?.message}
            defaultValue={defaultData?.type || "sale"}
          >
            <option value="sale">Sale</option>
            <option value="rent">Rent</option>
          </Select>
        </div>
        <div>
          <Label>Ownership</Label>
          <Select
            {...register("ownerShip")}
            color={errors?.ownerShip?.message && "failure"}
            helperText={errors?.ownerShip?.message}
            defaultValue={defaultData?.ownerShip || "Individual"}
          >
            <option value="Individual">Individual</option>
            <option value="Institutional">Institutional</option>
          </Select>
        </div>

        <div>
          <Label htmlFor="name">Name</Label>
          <TextInput
            type="text"
            placeholder="Enter name of Property"
            {...register("name")}
            color={errors?.name && "failure"}
            helperText={errors?.name?.message}
            defaultValue={defaultData?.name}
          />
        </div>
        <div>
          <Label>Price - Unit</Label>
          <div className="flex gap-x-4">
            <div className="grow">
              <TextInput
                type="number"
                step={1000}
                min={1000000}
                {...register("price.value")}
                placeholder="Enter Price Value"
                color={errors?.price?.value && "failure"}
                helperText={errors?.price?.value?.message}
                defaultValue={defaultData?.price?.value}
              />
            </div>
            <div>
              <Select
                {...register("price.unit")}
                color={errors?.price?.unit && "failure"}
                helperText={errors?.price?.unit?.message}
                defaultValue={defaultData?.price?.unit || "NPR"}
              >
                <option value="NPR">NPR</option>
                <option value="USD">USD</option>
              </Select>
            </div>
          </div>
        </div>

        <div>
          <Label>Furnished Status</Label>
          <Select
            {...register("furnishedStatus")}
            color={errors?.furnishedStatus && "failure"}
            helperText={errors?.furnishedStatus?.message}
            defaultValue={defaultData?.furnishedStatus || "furnished"}
          >
            <option value="furnished">Furnished</option>
            <option value="semiFurnished">Semi Furnished</option>
            <option value="noFurnished">No Furnished</option>
          </Select>
        </div>
        <div>
          <Label>Facing</Label>
          <Select
            {...register("facing")}
            color={errors?.facing && "failure"}
            helperText={errors?.facing?.message}
            defaultValue={defaultData?.facing || "east"}
          >
            <option value="east">East</option>
            <option value="west">West</option>
            <option value="north">North</option>
            <option value="south">South</option>
            <option value="northEast">North East</option>
            <option value="northWest">North West</option>
            <option value="southEast">South East</option>
            <option value="southWest">South West</option>
          </Select>
        </div>
        <div>
          <Label>Built Year</Label>
          <div className="flex gap-x-4">
            <TextInput
              type="number"
              placeholder="Enter year"
              className="grow"
              {...register("builtYear.year")}
              color={errors?.builtYear?.year?.message && "failure"}
              helperText={errors?.builtYear?.year?.message}
              defaultValue={defaultData?.builtYear?.year}
            />
            <Select
              {...register("builtYear.type")}
              defaultValue={defaultData?.builtYear?.type || "BS"}
            >
              <option value="AD">AD</option>
              <option value="BS">BS</option>
            </Select>
          </div>
        </div>
        <div>
          <Label>Coordinates</Label>
          <TextInput
            type="text"
            placeholder="Enter coordinates"
            {...register("coordinate")}
            color={errors?.coordinate?.message && "failure"}
            helperText={errors?.coordinate?.message}
            defaultValue={defaultData?.coordinate}
          />
        </div>
        <div>
          <Label>Enquiry To</Label>
          <Select
            {...register("enquiryTo")}
            color={errors?.enquiryTo?.message && "failure"}
            helperText={errors?.enquiryTo?.message}
            defaultValue={defaultData?.enquireyTo}
          >
            {enquiry?.results?.map((item) => (
              <option key={item?._id} value={item?._id}>
                {normalizeCamelCase(item?.name.split(" ")[0])}{" "}
                {normalizeCamelCase(item?.name.split(" ")[1])}
                {/* {`${normalizeCamelCase(
                  item?.name.split(" ")[0]
                )} ${normalizeCamelCase(item?.name.split(" ")[1])}`} */}
              </option>
            ))}
          </Select>
        </div>
      </fieldset>
    </>
  );
}

function OtherInfo({ control, register, setValue, watch, defaultData = null }) {
  const { setAlert } = useAlert();
  const [view, setView] = useState([]);
  const [files, setFiles] = useState([]);
  const [hasMoreDetail, setHasMoreDetail] = useState(false);
  const { fields, append, remove, update } = useFieldArray({
    name: "other",
    control,
  });

  function onFileSelect(e) {
    setFiles((prev) => [...prev, ...e.target.files]);
    setView([...view, new Array([...e.target.files].length).fill("")]);
  }

  useEffect(() => {
    if (defaultData?.images) {
      setValue("images", defaultData?.images);
    }
  }, [defaultData, setValue]);

  return (
    <>
      <section className="">
        <h2 className="text-2xl font-medium text-lime-700 mb-8">Images</h2>
        <fieldset className="mb-8">
          <div className="p-4">
            <label
              htmlFor="files-upload"
              className=" h-24 w-full bg-lime-50/50 border-2 rounded-xl border-sky-500 border-dashed flex items-center justify-center text-slate-400 cursor-pointer"
            >
              <span className="flex flex-col justify-center items-center">
                <FaFileImage className="text-teal-500" fontSize={"30px"} />
                Drop your image here
              </span>
            </label>
            <FileInput
              multiple
              accept="image/*"
              onChange={multiUpload("images", setValue, setAlert, {
                beforeUpload: onFileSelect,
                prev: watch("images") || [],
              })}
              id="files-upload"
              className="hidden"
            />
            <FileViewer
              watch={watch}
              setFiles={setFiles}
              setValue={setValue}
              files={files}
            />
          </div>
        </fieldset>
        <h2 className="text-2xl font-medium text-lime-700 mb-8">
          Other Details
        </h2>

        <fieldset>
          <Label className="flex items-center">
            More Details
            <ToggleSwitch
              className="ml-4"
              checked={hasMoreDetail}
              onChange={(e) => {
                if (e) append({});
                else remove();

                return setHasMoreDetail(e);
              }}
            />
          </Label>
          {hasMoreDetail && (
            <>
              <Table>
                <Table.Body>
                  {fields?.map((field, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <TextInput
                          placeholder="Title"
                          onChange={(e) =>
                            update(i, { ...field, key: e.target.value })
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Select
                          onChange={(e) =>
                            update(i, { ...field, type: e.target.value })
                          }
                        >
                          <option value="" selected>
                            Select Type
                          </option>
                          <option value="text">Text</option>
                          <option value="number">Number</option>
                          <option value="file">File</option>
                          <option value="switch">Switch</option>
                        </Select>
                      </Table.Cell>
                      <Table.Cell>
                        <FieldSelector
                          field={field}
                          register={register}
                          index={i}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Button onClick={() => append({ key: null, type: null })}>
                      <HiPlus className="mr-4 h-5 w-5" /> Add
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table>
            </>
          )}
        </fieldset>
      </section>
    </>
  );
}

function FieldSelector({ field, register, index }) {
  switch (field.type) {
    case "text":
      return <TextInput {...register(`other[${index}].value`)} />;
    case "number":
      return <TextInput type="number" {...register(`other[${index}].value`)} />;
    case "file":
      return <FileInput />;
    case "switch":
      return <ToggleSwitch {...register(`other[${index}].value`)} />;
    default:
      return <TextInput disabled placeholder="Please select a type first" />;
  }
}

function FileViewer({ watch, setValue }) {
  const [draggedIndex, setDraggedIndex] = useState(null);
  function handleOnDragStart(index) {
    return (e) => {
      setDraggedIndex(index);
      e.dataTransfer.setData("text/plain", "");
    };
  }

  function handleOnDragOver(e) {
    e.preventDefault();
  }
  function handleOnDragEnd() {
    setDraggedIndex(null);
  }
  function handleOnDrop(index) {
    return (e) => {
      e.stopPropagation();
      e.dataTransfer.dropEffect = "move";
      let images = watch("images");
      const newItems = [...images];
      const temp = newItems[draggedIndex];
      newItems[draggedIndex] = newItems[index];
      newItems[index] = temp;
      setValue("images", newItems);
    };
  }

  function handleDeleteImage(index) {
    return () => {
      let images = watch("images");

      const newItems = [...images];
      newItems.splice(index, 1);
      setValue("images", newItems);
    };
  }

  return (
    <>
      {watch("images")?.length > 0 && (
        <div className="grid grid-cols-3 gap-4 p-4">
          {watch("images")?.map((file, index) => (
            <div
              id={index}
              draggable
              onDragStart={handleOnDragStart(index)}
              onDragOver={handleOnDragOver}
              onDragEnd={handleOnDragEnd}
              onDrop={handleOnDrop(index)}
              className="border-2 rounded-xl p-4 space-y-4"
            >
              <div className="px-16 rounded-xl bg-slate-100 overflow-hidden">
                <img
                  className="aspect-square"
                  src={file.src}
                  alt={file?.title}
                />
              </div>
              <div>
                <div className="flex gap-x-2">
                  <Select
                    required
                    className="grow cursor-pointer"
                    defaultValue={file?.view}
                    onChange={(e) =>
                      setValue(`images[${index}].view`, e.target.value)
                    }
                  >
                    <option value="outsideView">Outside View</option>
                    <option value="insideView">Inside View</option>
                    <option value="kitchen">Kitchen</option>
                    <option value="bedroom">Bedroom</option>
                    <option value="bathroom">Bathroom</option>
                    <option value="hall">Hall</option>
                    <option value="corridor">Corridor</option>
                    <option value="front">Front</option>
                    <option value="back">Back</option>
                    <option value="dinningRoom">Dinning Room</option>
                    <option value="livingRoom">Living Room</option>
                    <option value="parking">Parking</option>
                    <option value="terrace">Terrace</option>
                    <option value="masterBedroom">Master Bedroom</option>
                    <option value="stairs">Stairs</option>
                    <option value="balconyGarden">Balcony Garden</option>
                    <option value="laundry">Laundry</option>
                    <option value="prayerRoom">Prayer Room</option>
                    <option value="drawingRoom">Drawing Room</option>
                  </Select>
                  <Button
                    color="gray"
                    onClick={handleDeleteImage(index)}
                    className="cursor-pointer border-2 border-red-500 focus:outline-none focus:ring-0 !text-red-600 hover:!bg-red-600 hover:!text-slate-100 transition-all duration-150 ease-in-out"
                  >
                    <MdOutlineDelete className="text-xl" />
                  </Button>
                </div>
                <i>{file?.name}</i>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default PropertyCreate;
