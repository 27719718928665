import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { VscLoading } from "react-icons/vsc";
import { Link } from "react-router-dom";
import commaSeparatedNum from "../../../_helpers/commaSeparatedNum";
import IntersectionObserverComponent from "../../../components/IntersectionObserver";
import UnorderedPagination from "../../../components/UnorderedPagination";
export default function CardComp({ cards, isLoading }) {
  const [isVisible, setIsVisible] = useState(false);
  const [hasPageChanged, setHasPageChanged] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(cards.length / 3);
  const [displayedCards, setDisplayedCards] = useState([
    cards?.[0],
    cards?.[1],
    cards?.[2],
    // cards?.[3],
  ]);

  async function onPageChange(page) {
    setHasPageChanged(true);
    setDisplayedCards([
      cards?.[page * 3 + 0],
      cards?.[page * 3 + 1],
      cards?.[page * 3 + 2],
      // cards?.[page * 3 + 3],
    ]);
    setCurrentPage(page);
    setTimeout(() => {
      setHasPageChanged(false);
    }, 500);
  }
  function handlePrevClick() {
    const prevPage = currentPage > 0 ? currentPage - 1 : totalPages - 1;
    onPageChange(prevPage);
  }

  function handleNextClick() {
    const nextPage = currentPage < totalPages - 1 ? currentPage + 1 : 0;
    onPageChange(nextPage);
  }
  useEffect(() => {
    if (cards) {
      setDisplayedCards([cards?.[0], cards?.[1], cards?.[2]]);
    }
  }, [cards]);

  window.log("featured data", cards, displayedCards, isLoading);

  return (
    <div className="w-screen flex justify-between relative">
      <IoIosArrowBack
        fontSize="30"
        className="absolute top-60 left-20 cursor-pointer"
        onClick={handlePrevClick}
      />
      <IoIosArrowForward
        fontSize="30"
        className="absolute top-60 right-20 cursor-pointer"
        onClick={handleNextClick}
      />
      <div
        className={[
          "md:w-5/6 md:px-4 md:px-auto mx-auto grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-x-4 pb-16 px-3 ",
          hasPageChanged ? "md:animate__animated animate__slideInRight" : "",
        ].join(" ")}
      >
        {isLoading ? (
          <div className="flex justify-center col-span-4 py-20">
            <VscLoading className="text-5xl animate-spin" />
          </div>
        ) : (
          displayedCards.map(
            (v, i) =>
              v && (
                <Link
                  key={i}
                  to={`/property/${v?.slug || v?._id}`}
                  className={[
                    "hover:scale-[1.025] transition-transform duration-150",
                  ].join(" ")}
                >
                  <div
                    key={i}
                    className={[
                      "md:space-x-0 space-x-[-1rem] md:space-y-0 space-y-[-1rem] border-none md:border rounded-3xl overflow-hidden",
                      "!shadow-big",
                      "relative",
                    ].join(" ")}
                  >
                    <IntersectionObserverComponent
                      setIsVisible={setIsVisible}
                      threshold={0}
                    >
                      <img
                        src={v?.images?.[0]?.src || "/assets/gp.gif"}
                        alt={v?.images?.[0]?.title}
                        className={[
                          "w-full object-cover object-center aspect-square",
                          isVisible
                            ? "md:animate__animated animate__bounceInUp"
                            : "md:opacity-0",
                        ].join(" ")}
                        loading="lazy"
                      />
                    </IntersectionObserverComponent>

                    <div className="absolute inset-0 z-10 bg-slate-900/60 opacity-0 hover:opacity-100 px-4 pt-4 pb-12 flex flex-col justify-between transition-opacity duration-300">
                      <div className="flex justify-end">
                        <p className="bg-lime-500 p-2 rounded-full text-xs">
                          Visit Property
                        </p>
                      </div>
                      <div className="font-semibold text-white">
                        <h1 className="">{v?.name}</h1>
                        <h2 className="text-base font-semibold text-white">
                          {v?.address}
                        </h2>
                      </div>
                      {/* <PropertyFeature /> */}
                    </div>
                    <div className="absolute inset-x-0 bottom-0 px-8 py-2 bg-slate-900/80 z-20">
                      <h1 className="text-lg text-white">
                        {v?.price?.unit} {commaSeparatedNum(v?.price?.value)}
                      </h1>
                    </div>
                  </div>
                </Link>
              )
          )
        )}

        <div className="hidden md:col-span-4 text-center md:flex space-x-4">
          {!isLoading && (
            <UnorderedPagination
              total={cards?.length}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}
