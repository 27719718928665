import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function Dashboard() {
  const _data = [
    { name: "House", value: 500 },
    { name: "Apartment", value: 200 },
    { name: "Land", value: 600 },
  ];

  const _barData = [
    { name: "January", apartment: 4000, house: 2400, land: 2400 },
    { name: "February", apartment: 3000, house: 1398, land: 2210 },
    { name: "March", apartment: 2000, house: 9800, land: 2290 },
    { name: "April", apartment: 2780, house: 3908, land: 2000 },
    { name: "May", apartment: 1890, house: 4800, land: 2181 },
    { name: "June", apartment: 2390, house: 3800, land: 2500 },
    { name: "July", apartment: 3490, house: 4300, land: 2100 },
    { name: "August", apartment: 2500, house: 2300, land: 2400 },
    { name: "September", apartment: 4500, house: 3200, land: 2700 },
    { name: "October", apartment: 3200, house: 2800, land: 2100 },
    { name: "November", apartment: 3800, house: 3400, land: 2000 },
    { name: "December", apartment: 4700, house: 3900, land: 1800 },
  ];

  return (
    <>
      <main className="">
        {/* <div className="grid md:grid-cols-3 grid-cols-1 h-min max-h-min gap-6 md:pt-0 pt-16">
					<PieChartComponent data={data} title="Sales by Property Type" />
					<div className="col-span-2">
						<BarChartComponent data={barData} title="Sales in month" />
					</div>
				</div> */}
      </main>
    </>
  );
}

export default Dashboard;

const _PieChartComponent = ({ data, title = "" }) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}% `}
        </text>
        <text
          x={x}
          y={y + 16}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {data[index]?.name}
        </text>
      </>
    );
  };
  return (
    <ResponsiveContainer
      width="100%"
      // height="100%"
      height={400}
      className="shadow-big rounded-3xl"
    >
      <p className="p-4">{title}</p>
      <PieChart height="100%" width="100%">
        <Pie
          data={data}
          cx="50%"
          cy="40%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius="80%"
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const _BarChartComponent = ({ data, title, dataKey = null }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={400}
      className="shadow-big rounded-3xl"
    >
      <p className="p-4">{title}</p>
      <BarChart
        width="100%"
        height={400}
        data={data}
        margin={{
          right: 32,
          left: 32,
          bottom: 32,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKey || "name"} />
        <YAxis />
        <Tooltip />
        <Legend />
        {Object.entries(data?.[0] || {})
          .map((entry) => {
            if (entry[0] === (dataKey || "name")) return null;
            else {
              return entry;
            }
          })
          .map(
            (entry) =>
              entry && (
                <Bar
                  dataKey={entry?.[0]}
                  fill={getRandomColor()}
                  activeBar={<Rectangle stroke="blue" />}
                />
              )
          )}
      </BarChart>
    </ResponsiveContainer>
  );
};

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
