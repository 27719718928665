import { useQuery } from "@tanstack/react-query";
import { BsHouseFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { MdSavings } from "react-icons/md";
import { getContent } from "../../apis";
import OurTeam from "../Home/OurTeam";
import WhyUs from "../Home/WhyUs";
export default function About() {
  const { data } = useQuery({
    queryKey: ["content"],
    queryFn: getContent,
  });

  return (
    <>
      <section
        className={[
          "min-h-[100dvh] w-screen overflow-hidden relative isolate",
          "py-16",
        ].join(" ")}
      >
        <div className="h-[50dvh] relative isolate before:absolute before:inset-0 before:bg-black/40">
          <img
            src="/assets/bharatpur.jpg"
            className="h-full w-full object-cover object-center"
            alt=""
          />
          <h1 className="md:text-4xl text-3xl font-bold text-white absolute md:top-1/2 top-[20%] left-1/2 -translate-x-1/2 -translate-y-1/2">
            {data?.data?.about?.mainText || "About Us"}
          </h1>
          <p className="md:text-xl text-base font-medium text-white absolute md:top-1/2 top-1/4 left-1/2 -translate-x-1/2 md:mt-6">
            {data?.data?.about?.subText ||
              "Your trusted guide to finding (or selling) your dream land, home, or apartment in Nepal. We simplify your real estate journey and provide expert guidance for a successful outcome."}
          </p>
        </div>
        <div className="py-6 md:py-16 md:w-3/4 mx-auto w-11/12 ">
          <h1 className="md:text-4xl text-2xl font-semibold text-center md:mb-12 mb-6">
            {data?.data?.about?.contentMainText ||
              "Our Mission is to find Best Property"}
          </h1>
          <div className="grid md:grid-cols-2 md:gap-16 grid-cols-1 gap-8">
            <div className="text-justify">
              <p className="text-slate-600 indent-8 md:mb-8 mb-4">
                {data?.data?.about?.content ||
                  `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis ab porro rerum quas vero. At debitis amet delectus a aliquid accusamus quaerat eveniet molestias. Soluta eaque quaerat vero natus ut blanditiis sint voluptates nesciunt, quasi ex pariatur laborum commodi illo sed iure, eveniet nobis, fugiat totam ab. Dolor, culpa soluta.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis ab porro rerum quas vero. At debitis amet delectus a aliquid accusamus quaerat eveniet molestias. Soluta eaque quaerat vero natus ut blanditiis sint voluptates nesciunt, quasi ex pariatur laborum commodi illo sed iure, eveniet nobis, fugiat totam ab. Dolor, culpa soluta.,Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis ab porro rerum quas vero. At debitis amet delectus a aliquid accusamus quaerat eveniet molestias. Soluta eaque quaerat vero natus ut blanditiis sint voluptates nesciunt, quasi ex pariatur laborum commodi illo sed iure, eveniet nobis, fugiat totam ab. Dolor, culpa soluta.`}
              </p>
              <div className="grid md:grid-cols-3 md:gap-x-4 text-center grid-cols-2 gap-4">
                <div className="bg-slate-100 p-4 rounded-3xl text-emerald-600 shadow-big">
                  <IoIosPeople className="text-5xl mx-auto hover:scale-150 transition-transform duration-300" />
                  <p>Customers</p>
                  <p>1000+</p>
                </div>
                <div className="bg-slate-100 p-4 rounded-3xl text-rose-600 shadow-big">
                  <BsHouseFill className="text-5xl mx-auto hover:scale-150 transition-transform duration-300" />
                  <p>Properties Sold</p>
                  <p>1000+</p>
                </div>
                <div className="bg-slate-100 p-4 rounded-3xl text-amber-600 shadow-big">
                  <MdSavings className="text-5xl mx-auto hover:scale-150 transition-transform duration-300" />
                  <p>Saved</p>
                  <p>Rs.10 million+</p>
                </div>
              </div>
            </div>
            <div>
              <img
                src={data?.data?.about?.contentImage || "/assets/gp.gif"}
                className="h-full object-cover object-center rounded-3xl shadow-big"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <WhyUs style={{ scrollSnapAlign: "center" }} />
      <OurTeam />
      {/* <section
        className="py-16 w-3/4 mx-auto"
        style={{ scrollSnapAlign: "center" }}
      >
        <h1 className="text-2xl md:text-4xl font-semibold text-center mb-16 text-primary">
          Our Team
        </h1>
        <div className="grid md:grid-cols-4 md:gap-8 grid-cols-1 gap-4">
          
          {ourTeam?.map((v) => (
            <div key={v}>
              <img
                src={v?.image}
                className="w-full aspect-square rounded-3xl shadow-big object-contain object-top"
                alt=""
              />
              <h3 className="text-xl md:text-2xl mt-8 text-center font-bold text-lime-700">
                {v?.name}
              </h3>
              <p className="text-center">{v?.position}</p>
            </div>
          ))}
        </div>
        <div className="py-16 relative z-20">
          <MapContainer
            center={[27.65814188398597, 85.34697794616557]}
            zoom={13}
            className="h-[50vh] overflow-clip w-full rounded-xl shadow-big -z-10"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[27.65814188398597, 85.34697794616557]}>
              <Popup>GP Real Estate Services Pvt. Ltd.</Popup>
            </Marker>
          </MapContainer>
        </div>
      </section> */}
      {/* <section className="py-16 w-3/4 mx-auto">
				<h1 className="text-4xl font-semibold text-center mb-12">
					Testimonials
				</h1>
				<div className="text-center">work in progress....</div>
			</section> */}
    </>
  );
}
