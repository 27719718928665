import { useQuery } from "@tanstack/react-query";
// import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { getTeams } from "../../apis";
export default function OurTeam() {
  const { data } = useQuery({
    queryKey: ["teams"],
    queryFn: getTeams,
  });

  return (
    <section
      className="py-16 w-3/4 mx-auto"
      style={{ scrollSnapAlign: "center" }}
    >
      <h1 className="text-2xl md:text-4xl font-semibold text-center mb-16 text-primary">
        Our Team
      </h1>
      <div className="grid md:grid-cols-4 md:gap-8 grid-cols-1 gap-4">
        {data?.data?.results?.map((v) => (
          <div key={v} className="">
            <img
              src={v?.image}
              className="w-full aspect-square rounded-3xl shadow-big object-cover object-top"
              alt=""
            />
            <h3 className="text-xl md:text-2xl mt-8 text-center font-bold text-lime-700">
              {v?.name}
            </h3>
            <p className="text-center">{v?.position}</p>
          </div>
        ))}
      </div>
      <div className="py-16">
        {/* <MapContainer
          center={[27.65814188398597, 85.34697794616557]}
          zoom={16}
          scrollWheelZoom={false}
          className="h-[50vh] overflow-clip w-full rounded-xl shadow-big -z-10"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[27.65814188398597, 85.34697794616557]}>
            <Popup>GP Real Estate Services Pvt. Ltd.</Popup>
          </Marker>
        </MapContainer> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.913629077081!2d85.34438297557953!3d27.658143827659785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb178b3b5b43d3%3A0x637711b740509cf8!2sG.P%20Real%20Estate%20Nepal%20%7C%20best%20real%20estate%20company%20in%20nepal!5e0!3m2!1sen!2snp!4v1722150504268!5m2!1sen!2snp"
          title="gp real estate location"
          width="600"
          height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="w-full"
        ></iframe>
        {/* <MapEmbed coordinate={("27.65831960706731", "85.3469793616614")} /> */}
      </div>
    </section>
  );
}
