import Cities from "./Cities";
import FAQ from "./FAQ";
import Featured from "./Featured";
import Hero from "./Hero";
import OurPartner from "./OurPartner";
import Popular from "./Popular";
import Premium from "./Premium";
import RentProperties from "./RentProperties";
import WhyUs from "./WhyUs";
function Home() {
  return (
    <>
      <Hero />
      <Featured />
      <Premium />
      <Popular />
      <RentProperties />
      <Cities />
      <WhyUs />
      <OurPartner />
      <FAQ />
    </>
  );
}

export default Home;
